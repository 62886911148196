import React from "react"

const NoSsr: React.FC = ({ children }) => {
  const [mounted, setMounted] = React.useState(false)

  React.useEffect(() => {
    setMounted(true)
  }, [])

  return <>{mounted ? children : null}</>
}

export default NoSsr
